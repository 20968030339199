import { db, getDocsFromArray } from "../globals/services";

export const RANDOMDEVIMG =
  "https://firebasestorage.googleapis.com/v0/b/spryte-s.appspot.com/o/devs%2Fbx2ip08WqIyW6zmw1r8X%2Fprofile%2Fportrait-g79725919c_1280.jpg?alt=media&token=67d74eb5-64be-44e7-aec5-a2df05b6ccf7";

export const PRIORITIES = ["None", "Low", "Medium", "High"];
export const PRIORITIESDATA = [
  { label: "None", value: 0 },
  { label: "Low", value: 1 },
  { label: "Medium", value: 2 },
  { label: "High", value: 3 },
];

export const PRIORITIESCOLORS = [
  { color: "black", bg: "#DCDCDC" },
  { color: "#3EAEFF", bg: "#CCF3FF" },
  { color: "#780B0B", bg: "#FFEECC" },
  { color: "#780B0B", bg: "#FCBBBB" },
];

export const slicedData = async (colGroupName, whereKey, list) => {
  let dataList = [];
  let dataNames = [];
  if (list.length > 10) {
    for (const data of list) {
      let stackDoc = await db
        .collectionGroup(colGroupName)
        .where(whereKey, "==", data)
        .get();

      let stack = stackDoc?.docs[0]?.data();
      if (stack) {
        dataList.push(stack);
        dataNames.push(stack.name);
      }
    }
    return [dataList, dataNames];
  } else {
    let stacks = await getDocsFromArray(colGroupName, whereKey, list);
    return stacks;
  }
};

export const getRequirementsNames = async (list) => {
  let stacks = list?.requirements?.stacks?.length
    ? await slicedData("technologies", "id", list?.requirements?.stacks)
    : [];
  let industryDomain = list?.requirements?.industryDomain?.length
    ? await slicedData(
        "subcollections",
        "id",
        list?.requirements?.industryDomain
      )
    : [];
  let projectTypes = list?.requirements?.projectTypes?.length
    ? await slicedData("industry", "id", list?.requirements?.projectTypes)
    : [];

  let reqNames = [
    ...(stacks?.[1] ?? []),
    ...(industryDomain?.[1] ?? []),
    ...(projectTypes ?? []),
  ];
  return reqNames;
};

export const getSingleListData = async (listDoc, user) => {
  let clientData = {};

  if (user) {
    let clientSnap = await listDoc.ref.parent.parent.get();
    clientData = clientSnap.data();
  }

  let list = listDoc.data();
  const {
    requirements,
    stats,
    createdOn,
    steps,
    partners,
    currentStep,
    status,
    teamLocations,
    ...listData
  } = list;
  let reqNames = await getRequirementsNames(list);
  let locationList =
    clientData?.spryteRating?.locations?.map((el) => el?.city) ?? [];
  let scoreAvg =
    Object.values(clientData?.spryteRating?.scoring ?? { noValue: 0 }).reduce(
      (a, b) => a + b,
      0
    ) /
    Object.values(clientData?.spryteRating?.scoring ?? { noValue: 1 }).length;

  if (list) {
    let data = {
      clientData: clientData,
      id: listDoc.id,
      scoreAvg: scoreAvg,
      listRef: listDoc.ref,
      requirements: requirements ?? [],
      stats: stats,
      openOn: createdOn ?? null,
      deadLine: steps?.[currentStep]?.end ?? null,
      competitors: 0,
      status: status ?? {},
      partners: partners ?? [],
      currentStep: currentStep,
      reqs: reqNames,
      locationList: locationList,
      teamLocations: teamLocations ?? [],
      steps: steps,
      ...listData,
    };
    return data;
  }
};
