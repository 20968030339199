import React, { useEffect, useState } from "react";
import { ReqTableRow } from "../../../globals/ReqTable/style";
import { GRIDCOLUMN } from "../constants";
import { PRIORITIESCOLORS, PRIORITIESDATA } from "../../../utils/ListUtils";
import { motion, AnimatePresence } from "framer-motion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SpDevCards from "../../../globals/SpDevCard";
import SpExcerptText from "../../../globals/SpExerptText/SpExcerptText";
import SingleMatchDev from "./SingleMatchDev";
import { SearchIcon } from "../../../globals/SpIcons";
import { useNavigate } from "react-router-dom";
import { getCollectionGroupRefFromPath } from "../../../globals/services";

// motion component variants for Info and Matches
const infoMatchesVariants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};

// motion component variants for Arrow icon
const arrowVariants = {
  open: { rotate: 180 },
  collapsed: { rotate: 0 },
};

const SingleReqBox = ({
  list,
  renderReqs,
  buildLocations,
  renderStatus,
  isSuperPartner,
  vendor,
  lastTerm,
  buildScores,
  buildTags,
  index,
}) => {
  const [showMore, setShowMore] = useState(false);
  const history = useNavigate();
  const [selectedPrio, setSelectedPrio] = useState(list?.priority || 0);
  const [matchDevs, setMatchDevs] = useState([]);
  const [matchScore, setMatchScore] = useState();

  // create a function that will take up to 100 and return the opacity value
  const getOpacity = (num) => {
    if (num > 100) return 1;
    if (num < 0) return 0;
    return num / 100;
  };

  // what is the rgb of #3fdd98
  const rgb = [63, 221, 152];

  useEffect(() => {
    if (list?.listRef && vendor?.vendorRef) {
      const getDevs = async () => {
        const devMatches = await getCollectionGroupRefFromPath("matches")
          .where("vendorRef", "==", vendor?.vendorRef)
          // .where("listRef", "==", list?.listRef)
          .get();
        let matchedDevs = {};
        await Promise.all(
          devMatches?.docs?.map?.(async (doc) => {
            if (
              doc?.data()?.source?.parent?.parent?.path === list?.listRef?.path
            ) {
              if (
                !Object?.keys(matchedDevs)?.includes(doc?.data()?.ref?.path)
              ) {
                const devDocData = await doc
                  ?.data()
                  ?.ref?.collection("devPublic")
                  ?.doc("profile")
                  ?.get();
                matchedDevs[doc?.data()?.ref?.path] = {
                  ...devDocData?.data(),
                  id: doc?.data()?.ref?.id,
                  reqMatchScore: doc?.data()?.mScore,
                };
              } else {
                matchedDevs[doc.data().ref.path].reqMatchScore = Math.max(
                  matchedDevs[doc?.data()?.ref?.path]?.reqMatchScore,
                  doc?.data()?.mScore
                );
              }
            }
          })
        );
        setMatchDevs(Object?.values(matchedDevs));
      };
      getDevs();

      // const getMatchScore = async () => {
      //   const vendorMatches = await vendor?.vendorRef
      //     ?.collection("matches")
      //     .get();
      //   let calculatedMScore = 0;
      //   await Promise.all(
      //     vendorMatches?.docs?.map(async (matchDoc) => {
      //       if (
      //         matchDoc?.data()?.source?.parent?.parent?.path ===
      //         list?.listRef?.path
      //       ) {
      //         if (calculatedMScore < matchDoc?.data()?.mScore)
      //           calculatedMScore = matchDoc?.data()?.mScore;
      //       }
      //     })
      //   );
      //   setMatchScore(calculatedMScore);
      // };
      // getMatchScore();
    }
  }, [list, vendor]);
  // console.log("list", list);
  const onPriorityChange = (e) => {
    const update = {
      priority: parseInt(e.target.value),
    };
    setSelectedPrio(e.target.value);
    list.listRef.update(update);
    e.persist();
  };

  return (
    <div className="requirementRow">
      <div
        style={{
          borderBottom: showMore ? "1px solid #DBDBDB" : "none",
          paddingBottom: showMore ? "1rem" : "0",
          marginBottom: showMore ? "1rem" : "0",
        }}
      >
        <ReqTableRow
          gridColumn={GRIDCOLUMN}
          className="requirementRow--top"
          // onClick={() => setShowMore(!showMore)}
        >
          <div className="matchScore ">
            <div
              style={{
                backgroundColor:
                  list?.vendorMatch > 0
                    ? `rgba(63, 221, 152, ${getOpacity(
                        list?.vendorMatch ?? 0
                      )})`
                    : "#F6F6F6",
                width: "50px",
                padding: "0.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="matchScore_count"
                style={{
                  fontWeight: 600,
                }}
              >
                {list?.vendorMatch?.toFixed(0) ?? 0}%
              </span>
            </div>
          </div>
          <div className="boldText deadLine">
            <span>
              {list?.deadLine?.toDate()?.toDateString()?.slice(4, 25) ??
                new Date().toDateString()?.slice(4, 25)}
            </span>
          </div>
          <div className="requirement boldText">
            <span>{renderReqs(list.reqs)}</span>
          </div>
          <div className="location boldText">
            <span>
              {list?.teamLocations
                ? buildLocations(list?.teamLocations, true)
                : "No Location"}
            </span>
          </div>
          <div className="type boldText">
            <span>
              {list?.requirements?.staffing ? "Staffing" : "Managed Project"}
            </span>
          </div>
          <div
            className="row_status boldText"
            style={{
              color: "#3FDD98",
            }}
          >
            <span>{list?.status?.id}</span>
          </div>
          <div className="priority boldText">
            {isSuperPartner ? (
              <select
                style={{
                  "--prioBg": PRIORITIESCOLORS[selectedPrio].bg,
                  "--prioColor": PRIORITIESCOLORS[selectedPrio].color,
                }}
                id="req-prio"
                onChange={onPriorityChange}
              >
                {PRIORITIESDATA?.map((val, i) => {
                  return (
                    <option
                      key={`${val.label}_${i}`}
                      value={val.value}
                      selected={list?.priority === val.value}
                    >
                      {val.label}
                    </option>
                  );
                })}
              </select>
            ) : list?.hasOwnProperty("priority") && list?.priority > 0 ? (
              <span
                style={{
                  color: PRIORITIESCOLORS[selectedPrio].color,
                }}
              >
                {PRIORITIESDATA[selectedPrio].label}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="createdOn boldText">
            <span>
              {list?.openOn?.toDate().toDateString().slice(4, 25) ??
                "No created On"}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {vendor?.id &&
            list.currentStep > 0 &&
            (isSuperPartner || vendor?.terms?.ref?.id === lastTerm?.id) ? (
              <SearchIcon
                cls="_spHover"
                stroke="black"
                onClick={() => {
                  history(`/requirements/${list.id}`);
                }}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              className="arrowDown"
              variants={arrowVariants}
              animate={showMore ? "open" : "collapsed"}
              onClick={() => setShowMore(!showMore)}
            >
              <KeyboardArrowDownIcon />
            </motion.div>
          </div>
        </ReqTableRow>
        {!showMore && (
          <SpExcerptText
            content={list?.description}
            maxContentheight={74}
            lines={2}
            id={list?.id}
            showShowMore={false}
          />
        )}
      </div>

      <AnimatePresence>
        {showMore && (
          <motion.div
            className="requirementRow--info"
            variants={infoMatchesVariants}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            <div
              className="requirementRow--info_match"
              style={{
                backgroundColor:
                  list?.vendorMatch > 0
                    ? `rgba(63, 221, 152, ${getOpacity(list?.vendorMatch)})`
                    : "#F6F6F6",
              }}
            >
              <span className="requirementRow--info_match__count">
                {list?.vendorMatch > 0
                  ? `${list?.vendorMatch?.toFixed(0)}%`
                  : "Not"}
              </span>
              <span className="requirementRow--info_match__text">
                {list?.vendorMatch > 0 ? "Match" : "Matched"}
              </span>
            </div>
            <div className="requirementRow--info_desc">
              <SpExcerptText
                content={list?.description}
                maxContentheight={74}
                lines={3}
                id={list?.listRef?.id + "out"}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {matchDevs?.length > 0 && (
        <AnimatePresence>
          {showMore && (
            <motion.div
              className="requirementRow--matches"
              variants={infoMatchesVariants}
              initial="collapsed"
              animate="open"
              exit="collapsed"
            >
              <h5>
                You have Matching Profiles ({matchDevs?.length} resources)
              </h5>
              <div className="requirementRow--matches_cards">
                {matchDevs
                  ?.sort((a, b) => b?.reqMatchScore - a?.reqMatchScore)
                  ?.slice(0, 6)
                  .map((devMatchDoc) => {
                    return (
                      <SingleMatchDev
                        devData={devMatchDoc}
                        key={devMatchDoc?.id}
                      />
                    );
                  })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default SingleReqBox;
