import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { getAllAsync } from "../globals/helpers";
import { colRef, db, docRef } from "../globals/services";
import { getSingleListData } from "../utils/ListUtils";
import { renderVendorData } from "../utils/VendorUtils";
import FirebaseContext from "./context";
import { AuthUserContext } from "./session";

export const GlobalVendorContex = createContext(null);

const GlobalVendorProvider = (props) => {
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // when super vendor type a vendor name
  const [searchedVendor, setSearchedVendor] = useState({});

  const [searchedVendId, setSearchedVendId] = useState("");
  // hold all data of the super vendor
  const [initialVendor, setInialVendor] = useState({});

  const [domainData, setDomainData] = useState({
    allDomains: [],
    domains: [],
    domainsObject: {},
    loading: false,
  });

  const [projectsData, setProjectsData] = useState({
    allProjects: [],
    projects: [],
    projectsObject: {},
    loading: false,
  });

  const [isDomainUpdated, setIsDomainUpdated] = useState(false);

  const [isProjectUpadted, setIsProjectUpdated] = useState(false);
  const [isVendorUpdated, setIsVendorUpdated] = useState(false);
  // check wether the super vendor reset the searched vendor or not
  const [isResetted, setIsResetted] = useState(true);

  //Vendor organization positions array
  const [orgPositions, setOrgPositions] = useState([]);

  //generics of the vendor it will be accessed in the account page and in the market page
  const [vendorGenerics, setVendorGenerics] = useState([]);

  //vendor trust score for the account page
  const [vendorTrust, setVendorTrust] = useState(0);

  // const [isVendorUpdated, setIsVendorUpdated] = useState(false);

  //storing the most populare queries we request with data we have in the in the collection queries in firebase
  const [popularQueries, setPopularQueries] = useState([]);

  const [paginatinPopular, setPaginatingPopular] = useState([]);

  const [lastQuery, setLastQuery] = useState(null);

  const [isAllQueries, setIsAllQUeries] = useState(false);
  const [listsObj, setListsObj] = useState({});
  const [listDef, setListDef] = useState({});
  const [isListLoading, setIsListLoading] = useState(true);

  // Dev profile context data
  const [isDev, setIsDev] = useState(false);
  const [isDevSelected, setIsDevSelected] = useState(false);
  const [devName, setDevName] = useState("");
  const [showDevData, setShowDevData] = useState(false);
  const [devPage, setDevPage] = useState("dev");
  const [isAllDevs, setIsAllDev] = useState(false);
  const [isPartnerDevs, setIsPartnerDevs] = useState(false);
  const [isVerifiedResource, setIsVerifiedResource] = useState(false);

  // const requirement data
  // array of array of requirements data
  const [reqsList, setReqsList] = useState([]);

  // document reference of the last requirement
  const [lastListDoc, setLastListDoc] = useState();
  // true when we query all requirements data in the database
  const [isAllList, setIsAllList] = useState(false);

  const [listQueryRef, setListQueryRef] = useState(
    db.collectionGroup("lists").orderBy("priority", "desc")
  );

  // checking for resume upload
  const [isUploading, setIsUploading] = useState(false);

  //In order to avoid unecessary rerender of the dev id
  const viewDev = useRef(null);
  const viewDraftDev = useRef(null);
  const viewingSection = useRef(null);

  // When is reset is true we will use the vendor data from the userauth context
  // else it means the vendor is searching for another vendor
  let vendor = isResetted ? authUser.vendor : searchedVendor;

  const isSubmitted = () => {
    setIsResetted(false);
  };

  const isReset = () => {
    setIsResetted(true);
  };

  useEffect(() => {
    let vendorSub;
    const getSearchedVendor = async () => {
      // when there is not auth we will set everything to empty

      if (authUser.auth) {
        setInialVendor(authUser.vendor);

        const vendorRef =
          searchedVendId !== "" && searchedVendId
            ? docRef(`vendors/${searchedVendId}`)
            : docRef(`vendors/${authUser.vendor.id}`);
        const vendorPublic = await vendorRef
          .collection("partnerPublic")
          .doc("profile")
          .get();

        vendorSub = vendorRef.onSnapshot((doc) => {
          if (doc.exists) {
            const vendorData = renderVendorData(doc);
            if (vendorPublic.exists) {
              vendorData.public = vendorPublic.data();
            }
            setSearchedVendor(vendorData);
          }
        });
      } else {
        setInialVendor({});
        setSearchedVendor({});
      }
      setIsVendorUpdated(false);
    };
    getSearchedVendor();
    return vendorSub;
  }, [authUser.vendor?.id, searchedVendId, authUser.auth]);

  useEffect(() => {
    if (authUser.vendor) {
      const getPopQueries = async () => {
        try {
          let popular = await firebase.getPopularQueriesWithLimit(10);
          setPopularQueries(popular[0]);
          setLastQuery(popular[1]);
        } catch (error) {}
      };
      getPopQueries();
    }
  }, [authUser.vendor?.id]);

  useEffect(() => {
    if (Object.keys(searchedVendor).length !== 0) {
      const positionStream = firebase.db
        .collection(`${searchedVendor.orgRef.path}/positions`)
        .onSnapshot((querysnap) => {
          let positions = [];
          let vendorGenerics = [];
          let emptyTrust = 0;
          querysnap.forEach((position) => {
            let genereicSetting = {
              positionRef: position.ref,
              position: position.data().label,
              minExp: 3,
              minProj: 3,
              trustScore: 2.5,
              isSelected: false,
            };
            emptyTrust += 2.5;
            let positionData = position.data();
            positionData["ref"] = position.ref;
            positionData[positionData?.label] = position.ref;
            positions.push(positionData);
            vendorGenerics.push(genereicSetting);
          });
          //check if the vendor has general Services in the settings section in the document
          //if general service does not exist the we will update the vendor documents
          //else we will just use the general service and set the state to the vendor genereal service
          if (searchedVendor.settings?.generalService?.length > 0) {
            setVendorGenerics(searchedVendor.settings?.generalService);
            let trustScore = 0;
            searchedVendor.settings?.generalService.forEach((generic) => {
              trustScore += generic.trustScore;
            });
            setVendorTrust(
              (
                trustScore / searchedVendor.settings?.generalService.length
              ).toFixed(2)
            );
          } else {
            setVendorGenerics(vendorGenerics);
            setVendorTrust(emptyTrust / positions.length);
            let updates = {};
            updates.settings = { ...searchedVendor.settings };
            updates.settings["generalService"] = vendorGenerics;
            firebase.updateVendor(searchedVendor.id, updates);
          }
          setOrgPositions(positions);
        });
      return () => {
        positionStream();
      };
    }
  }, [authUser.vendor, searchedVendId, searchedVendor]);

  // Fetch the list data
  useEffect(() => {
    const dataSetter = async (docs) => {
      if (docs?.length) {
        let listDatas = await Promise.all(
          docs.map(async (listDoc) => {
            let listData = await getSingleListData(listDoc, authUser.auth);
            return listData;
          })
        );

        setReqsList((prev) => {
          let lists = [...prev, ...listDatas];
          // remove duplicate lists by id
          let unique = lists.filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );
          return unique;
        });
      }
    };

    const getListData = async () => {
      setReqsList([]);
      let ref =
        listQueryRef ?? db.collectionGroup("lists").orderBy("priority", "desc");
      setIsListLoading(true);
      await getAllAsync(ref, dataSetter, 10);
      setIsListLoading(false);
    };

    getListData();
  }, [listQueryRef, authUser.auth]);

  // Update vendor match scores
  useEffect(() => {
    // console.log("renderin...");
    if (!searchedVendor?.id && reqsList?.length > 0) return;
    const getMatchScore = async (list, vendor) => {
      // console.log("vendor ", vendor);

      const vendorMatches = await vendor?.vendorRef
        ?.collection("matches")
        .get();
      let calculatedMScore = 0;
      await Promise.all(
        vendorMatches?.docs?.map(async (matchDoc) => {
          if (
            matchDoc?.data()?.source?.parent?.parent?.path ===
            list?.listRef?.path
          ) {
            if (calculatedMScore < matchDoc?.data()?.mScore)
              calculatedMScore = matchDoc?.data()?.mScore;
          }
        })
      );
      return calculatedMScore;
    };

    const updateVendorMatchScores = async () => {
      const updatedReqsList = await Promise.all(
        reqsList.map(async (list) => {
          const matchScore = await getMatchScore(list, searchedVendor);
          return { ...list, vendorMatch: matchScore };
        })
      );
      setReqsList(updatedReqsList);
    };

    updateVendorMatchScores();
  }, [reqsList?.length, searchedVendor?.id]);

  useEffect(() => {
    let orgSub;

    const getData = async () => {
      let spryteCollections = await firebase.db
        .collection("organisations")
        .where("name", "==", "Spryte Labs")
        .get();
      orgSub = await spryteCollections.docs[0].ref
        .collection("listDefs")
        .onSnapshot((snap) => {
          snap.docs.forEach((doc) => {
            let listData = doc.data();
            setListDef(listData);
          });
        });
    };

    if (searchedVendor?.id) getData();
    return () => {
      try {
        orgSub();
      } catch (e) {}
    };
  }, [searchedVendor?.id]);

  return (
    <GlobalVendorContex.Provider
      value={{
        searchedVendor,
        setSearchedVendor,
        initialVendor,
        setInialVendor,
        isResetted,
        isSubmitted,
        setIsResetted,
        isReset,
        searchedVendId,
        setSearchedVendId,
        setIsVendorUpdated,
        vendorGenerics,
        setVendorGenerics,
        vendor,

        domainData,
        projectsData,
        setIsDomainUpdated,
        setIsProjectUpdated,
        orgPositions,
        vendorTrust,
        popularQueries,
        setPopularQueries,
        setVendorTrust,
        lastQuery,
        setLastQuery,
        paginatinPopular,
        setPaginatingPopular,
        isAllQueries,
        setIsAllQUeries,
        listsObj,
        listDef,
        isListLoading,
        isDev,
        setIsDev,
        isDevSelected,
        setIsDevSelected,
        devName,
        setDevName,
        showDevData,
        setShowDevData,
        devPage,
        setDevPage,
        isAllDevs,
        setIsAllDev,
        isPartnerDevs,
        setIsPartnerDevs,
        isVerifiedResource,
        setIsVerifiedResource,
        isUploading,
        setIsUploading,
        viewDev,
        viewDraftDev,
        viewingSection,
        reqsList,
        setReqsList,
        lastListDoc,
        setLastListDoc,
        isAllList,
        setIsAllList,
      }}
    >
      {props.children}
    </GlobalVendorContex.Provider>
  );
};

export default GlobalVendorProvider;
